import axios from 'axios';
const API_URL = '/api/v1/game/';

class GameApi {
  create(payload) {
    return axios.post(API_URL, payload);
  }
  update(id, payload) {
    return axios.patch(API_URL + id, payload);
  }

  getGames(payload) {
    let { sortBy, sortDir, page, q, categories, features, platforms, count } = payload;

    return axios.get(API_URL, {
      params: { sortBy, sortDir, page, keyword: q, categories, features, platforms, count }
    });
  }

  getGameById(id) {
    return axios.get(API_URL + id);
  }

  delete(id) {
    return axios.delete(API_URL + id);
  }

  review(id, payload) {
    return axios.patch(API_URL + `review/${id}`, payload);
  }

  getFileGame(id) {
    return axios.get(API_URL + `filegame/${id}`);
  }
}
export default new GameApi();
