import axios from 'axios';
const API_URL = '/api/v1/upload/';

class UploadApi {
  uploadSingleFile(file) {
    const formData = new FormData();
    formData.append('image', file);
    return axios.post(API_URL, formData);
  }

  uploadFileGame(file) {
    const formData = new FormData();
    formData.append('fileGame', file);
    return axios.post(API_URL + 'game/file', formData);
  }

  uploadMultipleFiles(files) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('images', file);
    });
    return axios.post(API_URL + 'multiple', formData);
  }
}
export default new UploadApi();
