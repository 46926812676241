import axios from 'axios';
const API_URL = '/api/v1/user/';

class AccountApi {
  login(payload) {
    return axios.post(API_URL + 'signin', payload);
  }

  register(payload) {
    return axios.post(API_URL + 'signup', payload);
  }

  getUsers() {
    return axios.get(API_URL);
  }

  getUserById(id) {
    return axios.get(API_URL + id);
  }

  addCart(payload) {
    return axios.patch(API_URL + 'cart', payload);
  }

  getCart() {
    return axios.get(API_URL + 'checkout/cart');
  }

  deleteCart(id) {
    return axios.delete(API_URL + 'cart/' + id);
  }

  updatePassword(payload) {
    return axios.patch(API_URL + 'change-password', payload);
  }

  update(payload) {
    return axios.patch(API_URL, payload);
  }
}

export default new AccountApi();
