import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { isValidToken, setSession, verify } from '../utils/jwt';
import AccountApi from '../apis/account.api';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state, action) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username, password) => {
    const response = await AccountApi.login({ username, password });

    const { accessToken } = response.data;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: verify(accessToken, process.env.REACT_APP_JWT_SECRET_KEY)
      }
    });
  };

  const updateProfile = async (users) => {
    const response = await AccountApi.update(users);
    dispatch({
      type: 'UPDATE',
      payload: {
        user: response.data
      }
    });
  };

  const register = async (users) => {
    const { username, firstName, lastName, email, password, phone, confirmPassword } = users;
    const displayName = firstName + ' ' + lastName;
    const response = await AccountApi.register({ username, displayName, email, password, confirmPassword, phone });
    const { accessToken } = response.data;

    setSession(accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user: verify(accessToken, process.env.REACT_APP_JWT_SECRET_KEY)
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && !isValidToken(accessToken)) {
          logout();
        }
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const user = verify(accessToken, process.env.REACT_APP_JWT_SECRET_KEY);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);
  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        register,
        updateProfile,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export { AuthProvider, AuthContext };
