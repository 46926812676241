import axios from 'axios';
const API_URL = '/api/v1/category/';

class CategoryApi {
  create(payload) {
    return axios.post(API_URL, payload);
  }
  update(id, payload) {
    return axios.patch(API_URL + id, payload);
  }

  getCategories() {
    return axios.get(API_URL);
  }

  getAll() {
    return axios.get(API_URL + 'all');
  }

  delete(id) {
    return axios.delete(API_URL + id);
  }
}
export default new CategoryApi();
