// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  game: getIcon('ic_game'),
  category: getIcon('ic_category')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'app',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard
  //     }
  //   ]
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [{ title: 'list', path: PATH_DASHBOARD.user.list }]
      },

      // MANAGEMENT : GAME
      {
        title: 'game',
        path: PATH_DASHBOARD.game.root,
        icon: ICONS.game,
        children: [
          { title: 'list', path: PATH_DASHBOARD.game.list },
          { title: 'create', path: PATH_DASHBOARD.game.newGame }
        ]
      },

      // MANAGEMENT : CATEGORY
      {
        title: 'category',
        path: PATH_DASHBOARD.category.root,
        icon: ICONS.category,
        children: [
          { title: 'list', path: PATH_DASHBOARD.category.list },
        ]
      }
    ]
  }
];

export default sidebarConfig;
