import { useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
// pages
import Login from '../pages/authentication/Login';
// hooks
import useAuth from '../hooks/useAuth';

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [reqLocation, setReqLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== reqLocation) {
      setReqLocation(pathname);
    }
    return <Login />;
  }

  if (reqLocation && pathname !== reqLocation) {
    setReqLocation(null);
    return <Navigate to={reqLocation} />;
  }

  return <>{children}</>;
}
