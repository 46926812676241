export function fRouter(query, params) {
  let sortBy = query?.sortBy || params?.get('sortBy') || 'releaseDate';
  let sortDir = query?.sortDir || params?.get('sortDir') || 'DESC';
  let page = query?.page || params?.get('page') || 1;
  let q = query?.q || params?.get('q') || '';
  let count = query?.count || params?.get('count') || 40;
  let tag = params?.get('tag') || '';

  if (!tag.indexOf(query?.tag?.toLowerCase())) {
    tag = tag + `|${query?.tag?.toLowerCase()}`;
  } else if (!tag) {
    tag = query?.tag || '';
  }

  let url = `?${q && `q=${q}&`}sortBy=${sortBy}&sortDir=${sortDir}${tag && '&tag=' + tag}&count=${count}&page=${page}`;
  return url;
}

export function fParams(query) {
  const sortBy = query?.get('sortBy');
  const sortDir = query?.get('sortDir');
  const page = parseInt(query?.get('page')) || 1;
  const q = query?.get('q');
  const tag = query?.get('tag');
  const count = parseInt(query?.get('count')) || 40;
  return { sortBy, sortDir, page, q, tag, count };
}
