import axios from 'axios';
const API_URL = '/api/v1/order/';

class OrderApi {
  create() {
    return axios.post(API_URL);
  }

  getOrders() {
    return axios.get(API_URL);
  }

  getOrderById(id) {
    return axios.get(API_URL + id);
  }

  getOrdersUserId(userId) {
    return axios.get(API_URL + 'user/' + userId);
  }
}
export default new OrderApi();
