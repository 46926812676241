// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_HOME = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    pageOne: path(ROOTS_DASHBOARD, '/one'),
    pageTwo: path(ROOTS_DASHBOARD, '/two'),
    pageThree: path(ROOTS_DASHBOARD, '/three')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    userById: path(ROOTS_DASHBOARD, '/app/user/:userId')
  },
  game: {
    root: path(ROOTS_DASHBOARD, '/game'),
    list: path(ROOTS_DASHBOARD, '/game/list'),
    newGame: path(ROOTS_DASHBOARD, '/game/new'),
    gameById: path(ROOTS_DASHBOARD, '/game/:gameId'),
    editById: path(ROOTS_DASHBOARD, '/game/:gameId/edit')
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    list: path(ROOTS_DASHBOARD, '/category/list')
  }
};

export const PATH_PAGE = {
  root: '/',
  game: {
    root: path(ROOTS_HOME, '/game'),
    gameById: path(ROOTS_HOME, '/game/:gameId')
  },
  category: path(ROOTS_HOME, '/category/:categoryId'),
  checkout: path(ROOTS_HOME, '/checkout'),
  browse: path(ROOTS_HOME, '/browse'),
  genre: path(ROOTS_HOME, '/:genre-games'),
  account: path(ROOTS_HOME, '/account/personal')
};
