import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../routes/paths';
// hooks
import useAuth from '../hooks/useAuth';

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    if (user.role === 'admin') {
      return <Navigate to={PATH_DASHBOARD.root} />;
    } else {
      return <Navigate to={PATH_PAGE.root} />;
    }
  }
  return <>{children}</>;
}
