import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/home';

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['admin']}>
            <DashboardLayout />
          </RoleBasedGuard>{' '}
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/user/list" replace /> },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/list" replace /> },
            { path: 'list', element: <UserList /> },
            { path: 'account/:accountId', element: <UserInfo /> }
          ]
        },
        {
          path: 'game',
          children: [
            { path: '/', element: <Navigate to="/dashboard/game/list" replace /> },
            { path: 'list', element: <ProductList /> },
            { path: 'new', element: <ProductCreate /> },
            { path: ':gameId/edit', element: <ProductEdit /> }
          ]
        },
        {
          path: 'category',
          children: [
            { path: '/', element: <Navigate to="/dashboard/category/list" replace /> },
            { path: 'list', element: <CategoryList /> },
            { path: 'list/:categoryId', element: <CategoryList /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '500', element: <InternalServerError /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: (
        <RoleBasedGuard accessibleRoles={['user', 'guest']}>
          <MainLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: '/', element: <LandingPage /> },
        { path: '/game/:id/:name', element: <ProductDetail /> },
        { path: '/browse', element: <Browse /> },
        { path: '/:genre-games', element: <Browse /> }
        // { path: '/order-complete', element: <OrderComplete /> }
      ]
    },
    {
      path: '/order-complete',
      element: <OrderComplete />
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['user', 'guest']}>
            <MainLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '/checkout', element: <Checkout /> },
        { path: '/account/personal', element: <UserAccount /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));

// Dashboard
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserInfo = Loadable(lazy(() => import('../pages/dashboard/UserInfo')));
const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCreate')));
const ProductEdit = Loadable(lazy(() => import('../pages/dashboard/ProductCreate')));
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/Category')));
//Error
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const InternalServerError = Loadable(lazy(() => import('../pages/Page500')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const ProductDetail = Loadable(lazy(() => import('../pages/ProductDetail')));
const Checkout = Loadable(lazy(() => import('../pages/Checkout')));
const OrderComplete = Loadable(lazy(() => import('../pages/OrderComplete')));
const Browse = Loadable(lazy(() => import('../pages/Browse')));
const UserAccount = Loadable(lazy(() => import('../pages/UserAccount')));
