import PropTypes from 'prop-types';
// material
// import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  isLoading: PropTypes.bool
};

export default function Logo({ sx, isLoading }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 118, height: 32, ...sx }}>
      <img alt="register" src={isLoading ? '/favicon/icon.png' : '/favicon/logo.png'} />
    </Box>
  );
}
