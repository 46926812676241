import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD, PATH_PAGE } from '../routes/paths';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'user', 'guest']
  children: PropTypes.node
};

const useCurrentRole = () => {
  const { user } = useAuth();
  return user?.role || 'guest';
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRole = useCurrentRole();
  if (!accessibleRoles.includes(currentRole)) {
    if (currentRole === 'admin') {
      return <Navigate to={PATH_DASHBOARD.root} />;
    }
    return <Navigate to={PATH_PAGE.root} />;

    // return (
    //   <Container>
    //     <Alert severity="error">
    //       <AlertTitle>Permission Denied</AlertTitle>
    //       You do not have permission to access this page
    //     </Alert>
    //   </Container>
    // );
  }

  return <>{children}</>;
}
