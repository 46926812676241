// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const avatarDefault = '/static/mock-images/avatars/avatar_default.jpg';
  return (
    <MAvatar src={user.photoURL || avatarDefault} alt={user.displayName} color="default" {...other}>
      {user?.displayName?.charAt(0)}
    </MAvatar>
  );
}
